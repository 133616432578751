import { createApp, nextTick } from 'vue'; // nextTick for Posthog
import App from './App.vue';

import i18n from './i18n';
import router from './router';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
// Replace full MDI import with specific icons
// import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
// Import only the icons you need
import { 
  mdiAccount, 
  mdiChevronDown, 
  mdiChevronUp, 
  mdiCalendar, 
  mdiMagnify,
  mdiMenu,
  mdiClose,
  mdiPlus,
  mdiMinus,
  mdiCheck,
  mdiDelete,
  mdiPencil,
  mdiLogout,
  mdiCog,
  mdiInformation,
  mdiAlert,
  mdiArrowLeft,
  mdiArrowRight,
  mdiEmail,
  mdiFilePdfBox,
  mdiDownload,
  mdiExportVariant,
  mdiCamera,
  mdiGoogle,
  mdiMicrosoft,
  mdiFileDocumentOutline,
  mdiLightbulbOutline,
  mdiChatOutline,
  mdiDeleteOutline,
  mdiPlay,
  mdiPlayOutline,
  mdiFolder,
  mdiUpload,
  mdiContentCopy
} from '@mdi/js'

import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/dist/vuetify.min.css'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

// new for posthog
import posthogPlugin from "/plugins/posthog";

// new for sentry
// import * as Sentry from "@sentry/browser";
import * as Sentry from "@sentry/vue";


const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi-svg',
    aliases: {
      ...aliases,
      account: mdiAccount,
      chevronDown: mdiChevronDown,
      chevronUp: mdiChevronUp,
      calendar: mdiCalendar,
      magnify: mdiMagnify,
      menu: mdiMenu,
      close: mdiClose,
      plus: mdiPlus,
      minus: mdiMinus,
      check: mdiCheck,
      delete: mdiDelete,
      pencil: mdiPencil,
      logout: mdiLogout,
      settings: mdiCog,
      info: mdiInformation,
      alert: mdiAlert,
      arrowLeft: mdiArrowLeft,
      arrowRight: mdiArrowRight,
      email: mdiEmail,
      filePdfBox: mdiFilePdfBox,
      download: mdiDownload,
      exportVariant: mdiExportVariant,
      camera: mdiCamera,
      google: mdiGoogle,
      microsoft: mdiMicrosoft,
      fileDocumentOutline: mdiFileDocumentOutline,
      lightbulbOutline: mdiLightbulbOutline,
      chatOutline: mdiChatOutline,
      deleteOutline: mdiDeleteOutline,
      play: mdiPlay,
      playOutline: mdiPlayOutline,
      folder: mdiFolder,
      upload: mdiUpload,
      contentCopy: mdiContentCopy
    },
    sets: {
      'mdi-svg': mdi,
    },
  },
  components,
  directives,
  defaults: {
    Vbtn: {
      color: "primary",
      textTransform: 'none',
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#5932EA',
        csecondary: '#9197B3',
        backgroundsecondary: '#F2F2F7',
        background: '#FAFBFF',
        success: '#65C8CD',
        error: '#EC5B84',
      },
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
})


const app = createApp(App);


Sentry.init({
  app,
  dsn: "https://65a8bfc1a7c58ef6d59a0062fe3b01ff@o4507033516572672.ingest.us.sentry.io/4507033518211072",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "my-project-name@2.3.12",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.usetoday\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enableLongTask: true,
  longTaskIntervalMs: 1000, // Check every second
  longTaskThresholdMs: 200, // 200ms+ tasks considered blocking
  ignoreTransactions: ['/health-check'] // Optional
});

app.use(posthogPlugin)
  .use(i18n)
  .use(router)
  .use(Donut)
  .use(vuetify)
  .mount('#app');


router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
    });
  }
});