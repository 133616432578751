<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-title class="text-h4">
            Document Generator
          </v-card-title>
          <v-card-subtitle>
            Create documents using call data
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row v-if="!callData && !loading">
      <v-col cols="12">
        <v-card>
          <v-card-text class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
            <p class="mt-4">Loading call data...</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row v-else-if="callData && !pdfTemplate && !loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Upload PDF Template</v-card-title>
          <v-card-text>
            <p class="mb-4">Please upload a PDF template with form fields to fill with call data.</p>
            
            <v-form ref="uploadForm" @submit.prevent="extractFields">
              <v-file-input
                v-model="pdfTemplateFile"
                label="Select PDF Template"
                accept="application/pdf"
                :rules="[v => !!v || 'PDF template is required']"
                prepend-icon="$filePdfBox"
                class="mb-4"
              ></v-file-input>
              
              <v-text-field
                v-model="documentName"
                label="Document Name"
                :rules="[v => !!v || 'Document name is required']"
                :placeholder="defaultDocumentName"
                class="mb-4"
              ></v-text-field>
              
              <v-select
                v-model="documentType"
                :items="documentTypes"
                label="Document Type"
                class="mb-4"
              ></v-select>
              
              <v-btn
                type="submit"
                color="primary"
                block
                :loading="loading"
                :disabled="!pdfTemplateFile"
              >
                Extract Fields & Continue
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row v-else-if="pdfTemplate && !filledPdfUrl && !loading">
      <v-col cols="12">
        <v-card>
          <v-card-title>Review & Generate Document</v-card-title>
          <v-card-text>
            <p class="mb-4">The system has identified the following fields in your PDF template. Review the mappings and generate your document.</p>
            
            <v-expansion-panels class="mb-4">
              <v-expansion-panel>
                <v-expansion-panel-title>Call Information</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Call Name</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.call?.name || 'N/A' }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Date</v-list-item-title>
                      <v-list-item-subtitle>{{ formatDate(callData.call?.created_at) }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Has Transcript</v-list-item-title>
                      <v-list-item-subtitle>{{ hasTranscript ? 'Yes' : 'No' }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>
              
              <v-expansion-panel>
                <v-expansion-panel-title>Advisor Information</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list v-if="callData.advisor">
                    <v-list-item>
                      <v-list-item-title>Name</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.advisor.first_name }} {{ callData.advisor.last_name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.advisor.email || 'N/A' }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <p v-else>No advisor information available</p>
                </v-expansion-panel-text>
              </v-expansion-panel>
              
              <v-expansion-panel>
                <v-expansion-panel-title>Customer Information</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list v-if="callData.customer">
                    <v-list-item>
                      <v-list-item-title>Name</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.customer.first_name }} {{ callData.customer.last_name }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.customer.email || 'N/A' }}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>Phone</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.customer.phone || 'N/A' }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <p v-else>No customer information available</p>
                </v-expansion-panel-text>
              </v-expansion-panel>
              
              <v-expansion-panel>
                <v-expansion-panel-title>Organization Information</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list v-if="callData.organization">
                    <v-list-item>
                      <v-list-item-title>Name</v-list-item-title>
                      <v-list-item-subtitle>{{ callData.organization.name || 'N/A' }}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                  <p v-else>No organization information available</p>
                </v-expansion-panel-text>
              </v-expansion-panel>
              
              <v-expansion-panel v-if="pdfFields.length > 0">
                <v-expansion-panel-title>PDF Form Fields ({{ pdfFields.length }})</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-list>
                    <v-list-item v-for="field in pdfFields" :key="field">
                      <v-list-item-title>{{ field }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-text>
              </v-expansion-panel>
              
              <v-expansion-panel v-if="hasTranscript">
                <v-expansion-panel-title>Transcript Data</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <p>The system will extract entities and information from the transcript to fill the PDF.</p>
                  <v-checkbox
                    v-model="useTranscriptData"
                    label="Use transcript data for filling"
                    class="mt-2"
                  ></v-checkbox>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            
            <v-btn
              color="primary"
              block
              :loading="loading"
              @click="generateDocument"
              class="mt-4"
            >
              Generate Document
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <!-- Results Section -->
    <v-row v-else-if="filledPdfUrl">
      <v-col cols="12">
        <v-card>
          <v-card-title class="text-h5">Generated Document</v-card-title>
          <v-card-text>
            <v-alert type="success" class="mb-4">
              Your document has been successfully generated!
            </v-alert>
            
            <div class="d-flex justify-center mb-4">
              <v-btn
                color="primary"
                prepend-icon="$download"
                :href="filledPdfUrl"
                target="_blank"
              >
                Download Document
              </v-btn>
            </div>
            
            <div class="pdf-preview">
              <iframe :src="filledPdfUrl" width="100%" height="500px"></iframe>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row v-if="error">
      <v-col cols="12">
        <v-alert type="error" closable>
          {{ error }}
        </v-alert>
      </v-col>
    </v-row>
    
    <v-row v-if="info">
      <v-col cols="12">
        <v-alert type="info" closable>
          {{ info }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import { useRoute } from 'vue-router';

export default {
  name: 'PdfFillerView',
  
  data() {
    return {
      callId: null,
      callData: null,
      pdfTemplateFile: null,
      pdfTemplate: null,
      pdfFields: [],
      documentName: '',
      documentType: 'advisory',
      documentTypes: [
        'advisory',
        'contract',
        'report',
        'summary'
      ],
      useTranscriptData: true,
      loading: false,
      filledPdfUrl: null,
      error: null,
      info: null
    };
  },
  
  computed: {
    hasTranscript() {
      if (!this.callData || !this.callData.transcript) return false;
      return !!(this.callData.transcript.gladia || this.callData.transcript.meeting_auto);
    },
    
    defaultDocumentName() {
      if (!this.callData || !this.callData.call) return 'Document';
      return `${this.documentType} - ${this.callData.call.name || 'Call'}`;
    }
  },
  
  async created() {
    try {
      // Get call_id from route query
      const route = useRoute();
      this.callId = route.query.call_id;
      
      if (!this.callId) {
        this.error = 'No call ID provided. Please select a call first.';
        return;
      }
      
      // Load call data
      await this.loadCallData();
      
      // Set default document name
      this.documentName = this.defaultDocumentName;
    } catch (error) {
      console.error('Error initializing view:', error);
      this.error = 'Failed to initialize the document generator.';
    }
  },
  
  methods: {
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    
    async loadCallData() {
      try {
        this.loading = true;
        const response = await axios.get(`/api/transcripts?call_id=${this.callId}`);
        this.callData = response.data;
      } catch (error) {
        console.error('Error loading call data:', error);
        this.error = 'Failed to load call data. Please try again.';
      } finally {
        this.loading = false;
      }
    },
    
    async extractFields() {
      if (!this.pdfTemplateFile) {
        this.error = 'Please select a PDF template first.';
        return;
      }
      
      try {
        this.loading = true;
        
        // Create form data for file upload
        const formData = new FormData();
        
        // Log file details for debugging
        console.log('PDF file object type:', typeof this.pdfTemplateFile);
        console.log('PDF file object:', this.pdfTemplateFile);
        
        // Get the actual file object
        let fileObject = null;
        
        // Handle different types of file objects
        if (this.pdfTemplateFile instanceof File || this.pdfTemplateFile instanceof Blob) {
          // Standard File or Blob object
          console.log('Using standard File/Blob object');
          fileObject = this.pdfTemplateFile;
        } else if (Array.isArray(this.pdfTemplateFile) || (this.pdfTemplateFile && typeof this.pdfTemplateFile === 'object' && '0' in this.pdfTemplateFile)) {
          // Vuetify v-file-input in Vue 3 might return a proxied array
          console.log('Using file from array-like object');
          fileObject = this.pdfTemplateFile[0];
          console.log('Extracted file object:', fileObject);
        } else if (this.pdfTemplateFile && typeof this.pdfTemplateFile === 'object') {
          // Vuetify v-file-input might return a custom object
          console.log('Using custom object from Vuetify');
          
          // Check if it has a raw property that contains the File
          if (this.pdfTemplateFile.raw && (this.pdfTemplateFile.raw instanceof File || this.pdfTemplateFile.raw instanceof Blob)) {
            console.log('Using raw property from Vuetify object');
            fileObject = this.pdfTemplateFile.raw;
          } else {
            // Try to find a File object in the properties
            for (const key in this.pdfTemplateFile) {
              if (this.pdfTemplateFile[key] instanceof File || this.pdfTemplateFile[key] instanceof Blob) {
                console.log(`Found File object in property: ${key}`);
                fileObject = this.pdfTemplateFile[key];
                break;
              }
            }
          }
        }
        
        // Check if we found a valid file object
        if (!fileObject) {
          console.error('Could not extract a valid File object from:', this.pdfTemplateFile);
          throw new Error('Invalid file object: could not extract a File or Blob');
        }
        
        // Append the file to the form data
        console.log('Using file object:', fileObject);
        formData.append('pdf_template', fileObject, fileObject.name || 'document.pdf');
        
        // Log the FormData (note: FormData contents can't be directly logged)
        for (let pair of formData.entries()) {
          console.log(pair[0], typeof pair[1]);
        }
        
        // Use axios with the correct configuration
        const response = await axios.post('/api/extract-pdf-fields', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        
        // Store the extracted fields and template
        this.pdfFields = response.data.fields || [];
        this.pdfTemplate = this.pdfTemplateFile;
        
        if (this.pdfFields.length === 0) {
          this.error = 'No fillable fields found in the PDF template. Please select a different PDF with form fields.';
        } else {
          // Check if these are likely virtual fields (common field names or generic fields)
          const virtualFieldPatterns = [
            'text_content_page_',
            'customer_name',
            'customer_email',
            'advisor_name',
            'date',
            'signature'
          ];
          
          const hasVirtualFields = this.pdfFields.some(field => 
            virtualFieldPatterns.some(pattern => field.includes(pattern))
          );
          
          if (hasVirtualFields) {
            // Show a message that we're using virtual fields
            this.$nextTick(() => {
              this.info = 'This PDF does not have fillable form fields. The system will use AI to analyze the document visually and create virtual fields for data entry. A new PDF will be generated with your data overlaid on the original document.';
            });
          }
        }
      } catch (error) {
        console.error('Error extracting PDF fields:', error);
        this.error = 'Failed to extract fields from the PDF template: ' + (error.message || '');
      } finally {
        this.loading = false;
      }
    },
    
    async generateDocument() {
      try {
        this.loading = true;
        this.error = null;
        
        // Create form data for file upload
        const formData = new FormData();
        
        // Log file details for debugging
        console.log('PDF file object type:', typeof this.pdfTemplateFile);
        console.log('PDF file object:', this.pdfTemplateFile);
        
        // Get the actual file object
        let fileObject = null;
        
        // Handle different types of file objects
        if (this.pdfTemplateFile instanceof File || this.pdfTemplateFile instanceof Blob) {
          // Standard File or Blob object
          console.log('Using standard File/Blob object');
          fileObject = this.pdfTemplateFile;
        } else if (Array.isArray(this.pdfTemplateFile) || (this.pdfTemplateFile && typeof this.pdfTemplateFile === 'object' && '0' in this.pdfTemplateFile)) {
          // Vuetify v-file-input in Vue 3 might return a proxied array
          console.log('Using file from array-like object');
          fileObject = this.pdfTemplateFile[0];
          console.log('Extracted file object:', fileObject);
        } else if (this.pdfTemplateFile && typeof this.pdfTemplateFile === 'object') {
          // Vuetify v-file-input might return a custom object
          console.log('Using custom object from Vuetify');
          
          // Check if it has a raw property that contains the File
          if (this.pdfTemplateFile.raw && (this.pdfTemplateFile.raw instanceof File || this.pdfTemplateFile.raw instanceof Blob)) {
            console.log('Using raw property from Vuetify object');
            fileObject = this.pdfTemplateFile.raw;
          } else {
            // Try to find a File object in the properties
            for (const key in this.pdfTemplateFile) {
              if (this.pdfTemplateFile[key] instanceof File || this.pdfTemplateFile[key] instanceof Blob) {
                console.log(`Found File object in property: ${key}`);
                fileObject = this.pdfTemplateFile[key];
                break;
              }
            }
          }
        }
        
        // Check if we found a valid file object
        if (!fileObject) {
          console.error('Could not extract a valid File object from:', this.pdfTemplateFile);
          throw new Error('Invalid file object: could not extract a File or Blob');
        }
        
        // Append the file to the form data
        console.log('Using file object:', fileObject);
        formData.append('pdf_template', fileObject, fileObject.name || 'document.pdf');
        
        // Add other form fields
        formData.append('document_name', this.documentName);
        formData.append('document_type', this.documentType);
        formData.append('call_id', this.callId);
        
        if (this.useTranscriptData) {
          formData.append('use_transcript', 'true');
        }
        
        // Log the FormData (note: FormData contents can't be directly logged)
        for (let pair of formData.entries()) {
          console.log(pair[0], typeof pair[1]);
        }
        
        // Use axios with the correct configuration
        const response = await axios.post('/api/fill-pdf', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        
        // Handle success
        this.filledPdfUrl = response.data.pdf_url;
      } catch (error) {
        console.error('Error generating document:', error);
        this.error = error.response?.data?.error || error.message || 'An error occurred while generating the document.';
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.pdf-preview {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
</style> 