<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t('viewAuth.title') }}</v-breadcrumbs-item>
      <v-breadcrumbs-item>{{ $t('viewAuth.titleCalendar') }}</v-breadcrumbs-item>
    </v-breadcrumbs>
    <div style="margin-bottom: 15px; padding: 25px; flex:auto; align-items: left;">
      <h1 style="text-align: left">{{ $t('viewAuth.titleCalendar') }}</h1>
      <p style="text-align: left">{{ $t('viewAuth.descriptionCalendar') }}</p>
      <br>
      
      <!-- Calendar Sections Container -->
      <div class="calendar-sections">
        <!-- Google Calendar Section -->
        <div class="calendar-section">
          <h3>{{ $t('viewAuth.googleCalendarIntegration') }}</h3>
          <v-list v-if="googleConnections.length > 0">
            <v-list-item v-for="connection in googleConnections" :key="connection.id" class="calendar-item">
              <div class="calendar-content">
                <div class="d-flex align-center gap-3">
                  <CheckCircle class="check-icon" size="20" />
                  <span class="email-text">{{ connection.email }}</span>
                </div>
                <v-btn @click="deactivateGoogle(connection.id)" color="error" variant="text" class="disconnect-btn">
                  {{ $t('viewAuth.disconnect') }}
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
          
          <div v-else>
            <p class="text-body-1 mb-4">{{ $t('viewAuth.noGoogleCalendars') }}</p>
          </div>
        </div>
        
        <!-- Microsoft Calendar Section -->
        <div v-if="microsoftConnections.length > 0" class="calendar-section">
          <h3>{{ $t('viewAuth.connectedMicrosoftAccounts') }}</h3>
          <v-list>
            <v-list-item v-for="connection in microsoftConnections" :key="connection.id" class="calendar-item">
              <div class="calendar-content">
                <div class="d-flex align-center gap-3">
                  <CheckCircle class="check-icon" size="20" />
                  <span class="email-text">{{ connection.email }}</span>
                </div>
                <v-btn @click="deactivateMicrosoft(connection.id)" color="error" variant="text" class="disconnect-btn">
                  {{ $t('viewAuth.disconnect') }}
                </v-btn>
              </div>
            </v-list-item>
          </v-list>
        </div>

        <!-- Calendar Actions -->
        <div class="calendar-actions">
          <v-btn 
            v-if="googleConnections.length > 0"
            class="primary-btn"
            @click="authenticateGoogle"
          >
            <v-icon start icon="$google"></v-icon>
            {{ $t('viewAuth.connectAnotherGoogleCalendar') }}
          </v-btn>
          <v-btn 
            v-else
            class="primary-btn"
            @click="authenticateGoogle"
          >
            <v-icon start icon="$google"></v-icon>
            {{ $t('viewAuth.connectGoogleCalendar') }}
          </v-btn>

          <v-btn class="primary-btn" @click="authenticateMicrosoft">
            <v-icon start icon="$microsoft"></v-icon>
            {{ microsoftConnections.length > 0 ? $t('viewAuth.connectAnotherMicrosoft') : $t('viewAuth.connectMicrosoft') }}
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { supabase, userState } from "../supabase";
import { CheckCircle } from 'lucide-vue-next';

export default {
  components: {
    CheckCircle
  },
  setup() {
    const googleConnections = ref([]);
    const microsoftConnections = ref([]);

    const fetchConnections = async () => {
      try {
        const currentUser = userState.currentUser;
        const { data: initialConnections, error } = await supabase
          .from('calendar_auth')
          .select('*')
          .eq('user_id', currentUser.id)
          .eq('active', true);

        if (error) throw error;

        // Check for Google calendars without webhook IDs and create them
        const googleConnectionsWithoutWebhook = initialConnections.filter(
          conn => conn.calendar_type === 'Google' && !conn.webhook_id
        );

        let finalConnections = initialConnections;

        if (googleConnectionsWithoutWebhook.length > 0) {
          for (const conn of googleConnectionsWithoutWebhook) {
            try {
              const response = await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/api/google/webhook`, 
                { calendar_auth_id: conn.id }
              );
              
              if (response.data.webhook_id) {
                await supabase
                  .from('calendar_auth')
                  .update({ webhook_id: response.data.webhook_id })
                  .eq('id', conn.id);
              }
            } catch (webhookError) {
              console.error('Error setting up webhook for calendar:', webhookError);
            }
          }
          
          // Refresh connections after webhook setup
          const { data: updatedConnections } = await supabase
            .from('calendar_auth')
            .select('*')
            .eq('user_id', currentUser.id)
            .eq('active', true);
            
          if (updatedConnections) {
            finalConnections = updatedConnections;
          }
        }

        googleConnections.value = finalConnections.filter(conn => conn.calendar_type === 'Google');
        microsoftConnections.value = finalConnections.filter(conn => conn.calendar_type === 'Microsoft');
      } catch (error) {
        console.error('Error fetching connections:', error);
      }
    };

    const authenticateGoogle = async () => {
      try {
        const currentUser = userState.currentUser;
        const userId = currentUser.id;
        const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/authorizegoogle', {
          params: { user_id: userId }
        });
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Authorization failed:', error);
      }
    };

    const authenticateMicrosoft = async () => {
      try {
        const currentUser = userState.currentUser;
        const userId = currentUser.id;
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/authorizemicrosoft`, {
          params: { user_id: userId }
        });
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Authorization failed:', error);
      }
    };

    const deactivateGoogle = async (calendarAuthId) => {
      try {
        await supabase
          .from('calendar_auth')
          .update({ active: false })
          .eq('id', calendarAuthId)
          .eq('calendar_type', 'Google');
        
        await fetchConnections();
      } catch (error) {
        console.error('Error in deactivateGoogle:', error);
      }
    };

    const deactivateMicrosoft = async (calendarAuthId) => {
      try {
        await supabase
          .from('calendar_auth')
          .update({ active: false })
          .eq('id', calendarAuthId)
          .eq('calendar_type', 'Microsoft');
        
        await fetchConnections();
      } catch (error) {
        console.error('Error in deactivateMicrosoft:', error);
      }
    };

    onMounted(() => {
      fetchConnections();
    });

    return {
      googleConnections,
      microsoftConnections,
      authenticateGoogle,
      authenticateMicrosoft,
      deactivateGoogle,
      deactivateMicrosoft
    };
  }
};
</script>

<style scoped>
.primary-btn {
  background-color: #5932ea;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.mb-4 {
  margin-bottom: 1rem;
}

.calendar-item {
  padding: 12px 16px;
  margin-bottom: 8px;
  border-radius: 8px;
  background-color: #f8f9fc;
  transition: background-color 0.2s ease;
}

.calendar-item:hover {
  background-color: #f0f2f8;
}

.calendar-content {
  width: 100%;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-icon {
  color: #00AC4F;
  flex-shrink: 0;
}

.email-text {
  flex: 1;
  font-size: 14px;
  color: #333;
  text-align: left;
  margin: 0;
}

.disconnect-btn {
  min-width: auto;
  padding: 0 12px;
  color: #EC5B84;
  font-size: 14px;
  text-transform: none;
  margin-left: auto;
}

.disconnect-btn:hover {
  background-color: rgba(236, 91, 132, 0.1);
}

.calendar-section {
  margin-bottom: 2rem;
}

.calendar-section h3 {
  text-align: left;
  margin-bottom: 1rem;
  font-weight: 500;
}

.calendar-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .calendar-actions {
    flex-wrap: nowrap;
  }
  
  .calendar-actions .v-btn {
    flex: 1;
  }
}
</style>