<template>
  <div>
    <div class="accordion-header mb-4" @click="toggleEvents">
      <h2>{{ t('dashboard.upcomingEvents') }}</h2>
      <v-icon style="color: #9197B3; margin-left: 15px">
        {{ eventsVisible ? '$chevronUp' : '$chevronDown' }}
      </v-icon>
    </div>
    
    <div v-if="eventsVisible" class="upcoming-events">
      <div v-if="events.length">
        <div v-for="event in events" :key="event.id" class="event-row d-flex mb-4">
          <div class="calendar-icon-box me-4">
            <Calendar size="20" />
          </div>
          <div class="event-content">
            <div class="event-title">{{ event.summary }}</div>
            <div class="text-medium-emphasis event-time">
              {{ formatEventDate(event.start, event.end) }}{{ event.conversation_type ? ` | ${event.conversation_type}` : '' }}
            </div>
            <div v-if="event.conference_url">
              <a :href="event.conference_url" target="_blank" class="text-decoration-none">
                {{ getMeetingPlatformText(event.conference_url) }}
              </a>
            </div>
            <div v-else-if="event.bot_id === null" class="text-medium-emphasis">
              {{ t('common.noBotScheduled') }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center text-medium-emphasis py-4">
        {{ t('dashboard.noUpcomingEvents') }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { supabase } from '../supabase';
import { userState } from '../supabase.js';
import { Calendar } from 'lucide-vue-next';
import { format, isToday, isTomorrow } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const events = ref([]);
const eventsVisible = ref(true);
const locales = { de, en: enUS };

const toggleEvents = () => {
  eventsVisible.value = !eventsVisible.value;
};

const formatEventDate = (startTime, endTime) => {
  if (!startTime || !endTime) return '';
  
  try {
    const start = new Date(startTime.replace(' ', 'T'));
    const end = new Date(endTime.replace(' ', 'T'));
    const currentLocale = locales[localStorage.getItem('language') || 'en'];
    
    let dateStr = '';
    if (isToday(start)) {
      dateStr = t('common.today');
    } else if (isTomorrow(start)) {
      dateStr = t('common.tomorrow');
    } else {
      dateStr = format(start, 'dd.MM.yyyy', { locale: currentLocale });
    }
    
    const timeStr = `${format(start, 'HH:mm')}-${format(end, 'HH:mm')}`;
    return `${dateStr}, ${timeStr}`;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
};

const getMeetingPlatformText = (link) => {
  const url = link.toLowerCase();
  if (url.includes('zoom')) return t('common.joinZoom');
  if (url.includes('teams')) return t('common.joinTeams');
  if (url.includes('meet')) return t('common.joinMeet');
  return t('common.joinMeeting');
};

const fetchEvents = async () => {
  try {
    if (!userState.currentUser?.id) return;
    
    const { data: calendarAuths } = await supabase
      .from('calendar_auth')
      .select('id')
      .eq('user_id', userState.currentUser.id.toString())
      .eq('active', true);


    if (!calendarAuths?.length) return;
    
    // Get all events, including those without a bot
    const { data: eventsData } = await supabase
      .from('events')
      .select('*')
      .in('calendar_auth_id', calendarAuths.map(auth => auth.id))
      .gte('start', new Date().toISOString())
      .order('start', { ascending: true })
      .limit(5);

    if (eventsData) {
      const eventsWithTypes = await Promise.all(eventsData.map(async (event) => {
        const { data: callData } = await supabase
          .from('calls')
          .select('scorecard_id')
          .eq('event_id', event.id)
          .maybeSingle();

        let conversationType = '';

        if (callData?.scorecard_id) {
          const { data: convType } = await supabase
            .from('conversationtypes')
            .select('name')
            .eq('associated_scorecard', callData.scorecard_id)
            .maybeSingle();
          
          conversationType = convType?.name || '';
        }

        return {
          ...event,
          conversation_type: conversationType
        };
      }));

      events.value = eventsWithTypes;
    }
  } catch (error) {
    console.error('Error fetching events:', error);
  }
};

onMounted(() => {
  fetchEvents();
});
</script>

<style scoped>
.upcoming-events {
  background: white;
  border-radius: 16px;
  padding: 20px;
  font-family: 'Poppins', sans-serif;
}

.accordion-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 30px;
  margin-right: 15px;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  color: #000;
  margin: 0;
}

.event-title {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 1.4;
}

.event-time, a {
  font-size: 14px;
  font-weight: 400;
}

.text-medium-emphasis {
  color: #9197B3;
  font-size: 14px;
}

.calendar-icon-box {
  background-color: #5932EA;
  width: 48px;
  height: 48px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  align-self: flex-start;
  margin-top: 4px;
}

.calendar-icon-box :deep(svg) {
  color: white;
  stroke: currentColor;
  stroke-width: 2;
}

.event-row {
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s;
  align-items: flex-start;
}

.event-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.event-row:hover {
  background-color: #F9F9F9;
}

a {
  color: #5932EA;
}

a:hover {
  opacity: 0.8;
}

.event-content > div {
  margin-bottom: 0;
}

.event-content > div:last-child {
  margin-bottom: 0;
}

.calendar-connect-btn {
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.btn-text {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px; 
}

@media (max-width: 280px) {
  .btn-text {
    max-width: 200px;
  }
}
</style> 