<template>
  <!-- Personalized greeting and onboarding actions -->
  <h2 style="margin-bottom: 20px; text-align: left" v-if="currentUser">
    {{ $t(greetingPre) }}<span class="primary-name">{{ firstName }}</span>{{ $t(greetingPost) }}
  </h2>
  <h2 style="margin-bottom: 20px; text-align: left" v-else>{{ $t('dashboard.welcome') }}</h2>

  <!-- Accordion Header to toggle visibility of onboarding cards -->
  <div class="accordion-header mb-2" @click="toggleCards" v-if="!isAllDismissed">
    <h3>{{ $t('dashboard.onboarding') }}</h3>
    <v-icon style="color: #9197b3; margin-left: 15px">
      {{ cardsVisible ? '$chevronUp' : '$chevronDown' }}
    </v-icon>
  </div>

  <!-- Onboarding Cards -->
  <div class="card-container mb-15" v-if="cardsVisible && !isAllDismissed">
    <v-card v-for="card in visibleCards" :key="card.id" class="card rounded-xl">
      <v-card-title align="start">
        {{ $t(card.title) }}
        <v-btn icon size="small" density="compact" elevation="0" class="close-btn" @click="closeCard(card.id)">
          <v-icon icon="$close"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text align="start">{{ $t(card.text) }}</v-card-text>
      <v-card-actions class="card-actions" style="margin-bottom: 20px;">
        <div v-if="card.type === 'upload'" class="button-wrapper">
          <v-btn @click="routeforward" prepend-icon="$exportVariant" class="secondary-btn" color="white">
            {{ $t('dashboard.upload') }}
          </v-btn>
        </div>
        <div v-else-if="card.type === 'demo'" class="button-wrapper">
          <v-btn @click="toExample" class="primary-btn">{{ $t('dashboard.demo') }}</v-btn>
        </div>
        <div v-else-if="card.type === 'calendar'" class="button-wrapper">
          <v-btn @click="toCalendarSync" class="secondary-btn" color="white">{{ $t('dashboard.calendar') }}</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
  <CrosssellingTable v-if="isTeamLead" class="mb-15" />
  <TeamLeadOverview v-if="isTeamLead" class="mb-15" />
  <UpcomingEvents class="mb-15" />
  <dashboard-analytics v-if="isTeamLead"/>

</template>

<script setup>
import { useRouter, useRoute } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import { userState } from '../supabase.js';
import { supabase } from "../supabase";
import DashboardAnalytics from '@/components/DashboardAnalytics.vue';
import CrosssellingTable from '@/components/CrosssellingTable.vue';
import TeamLeadOverview from '@/components/TeamLeadOverview.vue';
import UpcomingEvents from '@/components/UpcomingEvents.vue';

const router = useRouter();
const route = useRoute();
const tenant = computed(() => route.params.tenant);
const advisorData = ref(null);
const currentUser = computed(() => userState.currentUser);
const organisationId = ref(null);

// Card data and methods
const cards = ref([
  { id: 1, title: "dashboard.cards.demo.title", text: "dashboard.cards.demo.description", isVisible: true, type: 'demo' },
  { id: 3, title: "dashboard.cards.calendar.title", text: "dashboard.cards.calendar.description", isVisible: true, type: 'calendar' },
]);

const cardsVisible = ref(true);
// eslint-disable-next-line no-unused-vars
const components = { DashboardAnalytics, CrosssellingTable, TeamLeadOverview };
const visibleCards = computed(() => {
  return cards.value.filter(card => card.isVisible);
});

// Check for calendars that need reauth
const checkCalendarReauth = async () => {
  try {
    if (!userState.currentUser?.id) return;
    
    // Look for inactive calendar auths that have webhook expiry dates
    // This indicates they were previously active but the token was revoked
    const { data: microsoftCalendars } = await supabase
      .from('calendar_auth')
      .select('*')
      .eq('user_id', userState.currentUser.id.toString())
      .eq('active', false)
      .not('microsoft_webhook_expiry', 'is', null)
      .eq('calendar_type', 'Microsoft');
      
    const { data: googleCalendars } = await supabase
      .from('calendar_auth')
      .select('*')
      .eq('user_id', userState.currentUser.id.toString())
      .eq('active', false)
      .not('google_webhook_expiry', 'is', null)
      .eq('calendar_type', 'Google');
    
    // Combine results
    const needsReauth = [...(microsoftCalendars || []), ...(googleCalendars || [])];
    
    if (needsReauth.length > 0) {
      // Add the calendar reauth card if not already present
      const reauthCardExists = cards.value.some(card => card.id === 4);
      if (!reauthCardExists) {
        cards.value.push({
          id: 4,
          title: "dashboard.cards.calendarReauth.title",
          text: "dashboard.cards.calendarReauth.description",
          isVisible: true,
          type: 'calendar'
        });
      }
    }
  } catch (error) {
    console.error('Error checking calendar reauth:', error);
  }
};

const greetingPre = computed(() => {
  const now = new Date();
  const hour = now.getHours();

  if (hour >= 0 && hour < 12) {
    return 'dashboard.greeting.morning';
  } else if (hour >= 12 && hour < 18) {
    return 'dashboard.greeting.afternoon';
  } else if (hour >= 18) {
    return 'dashboard.greeting.evening';
  }
  return '';
});

const firstName = computed(() => {
  return advisorData.value ? advisorData.value.first_name : '';
});

const greetingPost = computed(() => {
  return ' 👋';
});

function toggleCards() {
  cardsVisible.value = !cardsVisible.value;
}


async function updateOnboardingData(cardType, dismissed) {
  const onboardingData = advisorData.value.onboarding || {};
  onboardingData[`${cardType}-dismissed`] = dismissed;

  try {
    await supabase
      .from('advisors')
      .update({ onboarding: onboardingData })
      .eq('user_id', userState.currentUser.id.toString());
  } catch (error) {
    console.warn('Error updating onboarding data:', error.message);
  }
}

async function fetchAdvisorData() {
  try {
    const { data: advisors, error } = await supabase
      .from('advisors')
      .select('first_name, onboarding, organisation, direct_reports')
      .eq('user_id', userState.currentUser.id.toString());

    if (error) throw error;
    if (advisors && advisors.length > 0) {
      advisorData.value = advisors[0];
      organisationId.value = advisors[0].organisation;
      const onboardingData = advisors[0].onboarding || {};
      cards.value.forEach(card => {
        card.isVisible = !onboardingData[`${card.type}-dismissed`];
      });
    }
    await fetchDemoCallId();
  } catch (error) {
    console.warn(error.message);
  }
}

async function fetchDemoCallId() {
  if (!organisationId.value) {
    console.warn('Organisation ID is not available.');
    return 230; // Return 230 as a backup
  }

  try {
    const { data, error } = await supabase
      .from('organisations')
      .select('demo_call')
      .eq('id', organisationId.value)
      .single();
    if (error) throw error;
    return data?.demo_call ?? 230; // Return 230 as a backup if demo_call is not available
  } catch (error) {
    console.error('Error fetching demo call ID:', error.message);
    return 230; // Return 230 as a backup in case of an error
  }
}

const isAllDismissed = computed(() => {
  const onboarding = advisorData.value?.onboarding || {};
  return onboarding['demo-dismissed'] &&
    onboarding['upload-dismissed'] &&
    onboarding['calendar-dismissed'];
});

const isTeamLead = computed(() => {
  const result = advisorData.value?.direct_reports !== null;
  return result;
});

onMounted(async () => {
  await fetchAdvisorData();
  fetchDemoCallId();
  checkCalendarReauth();
});

function closeCard(cardId) {
  const card = cards.value.find(c => c.id === cardId);
  if (card) {
    card.isVisible = false;
    updateOnboardingData(card.type, true);
  }
}

async function routeforward() {
  router.push({ name: 'upload', params: { tenant: tenant.value } });
}

async function toExample() {
  const demoCallId = await fetchDemoCallId();
  if (demoCallId) {
    router.push({ name: 'call details', params: { id: demoCallId, tenant: tenant.value } });
  } else {
    console.error('Demo call ID could not be fetched.');
  }
}

async function toCalendarSync() {
  router.push({ name: 'auth', params: { tenant: tenant.value } });
}
</script>

<style scoped>
.accordion-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 30px;
  margin-right: 15px;
}

.primary-name {
  color: #5932ea;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-container {
  text-align: center;
  align-items: bottom;
  margin-bottom: 10px;
}

.card {
  display: flex;
  width: 300px;
  flex-direction: column;
  padding: 5px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-actions {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.button-wrapper {
  display: inline-block;
  margin-bottom: 10px;
}

.close-btn {
  position: absolute;
  color: #9197b3;
  border: 1px;
  border-color: #9197b3;
  width: 2px;
  height: 2px;
  top: 5px;
  right: 5px;
  margin: 10px;
}

.primary-btn {
  background-color: #5932ea;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.secondary-btn {
  background-color: #9197b3;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.btn-group>* {
  margin-right: 30px;
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}
</style>
