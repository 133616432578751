<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6">
        <v-card class="pa-6 rounded-lg">
          <!-- Step 1: Language Selection -->
          <div v-if="currentStep === 1">
            <h1 class="text-center text-h4 mb-4">
              Willkommen bei Valeo
            </h1>
            <p class="text-center mb-6">
              Wähle deine präferierte Sprache
            </p>
            <p class="text-center mb-6">
              Choose your preferred language
            </p>
            
            <v-select
              v-model="selectedLanguage"
              :items="languages"
              item-title="name"
              item-value="code"
              return-object
              label="Sprache wählen"
              class="mb-6"
            >
              <template v-slot:selection="{ item }">
                <v-avatar size="24" class="me-2">
                  <v-img :src="item.raw.flag" contain></v-img>
                </v-avatar>
                {{ item.raw.name }}
              </template>
              <template v-slot:item="{ item, props }">
                <v-list-item v-bind="props">
                  <template v-slot:prepend>
                    <v-avatar size="24" class="me-2">
                      <v-img :src="item.raw.flag" contain></v-img>
                    </v-avatar>
                  </template>
                  <v-list-item-title>{{ item.raw.name }}</v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
            
            <v-btn 
              block 
              color="primary" 
              size="large" 
              @click="nextStep" 
              :disabled="!selectedLanguage"
              class="mt-4"
            >
              {{ $t('common.continue') }}
            </v-btn>
            
          </div>
          
          <!-- Step 2: Contract Options -->
          <div v-else-if="currentStep === 2">
            <v-btn icon class="back-button mb-4" @click="currentStep = 1">
              <ArrowLeft />
            </v-btn>
            
            <h3 class="text-center mb-6">{{ $t('common.existingContractQuestion') }}</h3>
            
            <v-row>
              <v-col cols="12" md="6">
                <v-card 
                  height="200" 
                  class="d-flex align-center justify-center contract-option" 
                  @click="selectContractOption('existing')"
                  hover
                >
                  <div class="text-center pa-4">
                    <v-icon size="x-large" color="primary" class="mb-2">mdi-file-document-outline</v-icon>
                    <h3>{{ $t('common.yes') }}</h3>
                  </div>
                </v-card>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-card 
                  height="200" 
                  class="d-flex align-center justify-center contract-option" 
                  @click="selectContractOption('new')"
                  hover
                >
                  <div class="text-center pa-4">
                    <v-icon size="x-large" color="primary" class="mb-2">mdi-file-plus-outline</v-icon>
                    <h3>{{ $t('common.no') }}</h3>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
          
          <!-- Step 3: Video and Form -->
          <div v-else-if="currentStep === 3">
            <v-btn icon class="back-button mb-4" @click="currentStep = 2">
              <ArrowLeft />
            </v-btn>
            
            <div class="video-container mb-6">
              <iframe 
                v-if="contractOption === 'existing'"
                width="100%" 
                height="315" 
                src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              ></iframe>
              <iframe 
                v-else
                width="100%" 
                height="315" 
                src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen
              ></iframe>
            </div>
            
            <v-form ref="form" v-model="isFormValid">
              <v-text-field
                v-model="formData.name"
                :label="$t('common.name')"
                required
                :rules="[v => !!v || $t('common.nameRequired')]"
              ></v-text-field>
              
              <v-text-field
                v-model="formData.email"
                :label="$t('common.email')"
                required
                :rules="[
                  v => !!v || $t('common.emailRequired'),
                  v => /.+@.+\..+/.test(v) || $t('common.emailInvalid')
                ]"
              ></v-text-field>
              
              <v-text-field
                v-model="formData.phone"
                :label="$t('common.phone')"
                required
                :rules="[v => !!v || $t('common.phoneRequired')]"
              ></v-text-field>
              
              <v-checkbox
                v-model="formData.privacyAccepted"
                required
                :rules="[v => !!v || $t('common.privacyRequired')]"
              >
                <template v-slot:label>
                  {{ $t('common.privacyText') }}
                  <a :href="privacyPdfUrl" target="_blank" class="privacy-link">{{ $t('common.privacyLink') }}</a>
                  {{ $t('common.privacyTextEnd') }}
                </template>
              </v-checkbox>
              
              <v-btn 
                block 
                color="primary" 
                size="large" 
                @click="submitForm" 
                :disabled="!isFormValid"
                :loading="isSubmitting"
              >
                {{ $t('common.send') }}
              </v-btn>
            </v-form>
          </div>
          
          <!-- Step 4: Success Message -->
          <div v-else-if="currentStep === 4" class="text-center py-6">
            <v-icon color="success" size="x-large" class="mb-4">mdi-check-circle</v-icon>
            <h2 class="text-h5 mb-4">{{ $t('formSuccess') }}</h2>
            <v-btn color="primary" @click="resetForm">{{ $t('backToStart') }}</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { ArrowLeft } from 'lucide-vue-next';

const i18n = useI18n();
const currentStep = ref(1);
const selectedLanguage = ref(null);
const contractOption = ref(null);
const isFormValid = ref(false);
const isSubmitting = ref(false);
const form = ref(null);

const formData = ref({
  name: '',
  email: '',
  phone: '',
  message: '',
  privacyAccepted: false
});

const languages = [
  { 
    name: 'Deutsch', 
    code: 'de',
    flag: '/img/flags/de.svg' 
  },
  { 
    name: 'English', 
    code: 'en',
    flag: '/img/flags/gb.svg' 
  },
  { 
    name: 'हिन्दी', 
    code: 'hi',
    flag: '/img/flags/in.svg' 
  }
];

const privacyPdfUrl = '/privacy-policy-sample.pdf';

watch(selectedLanguage, (newLang) => {
  if (newLang) {
    i18n.locale.value = newLang.code;
    localStorage.setItem('selectedLanguage', newLang.code);
  }
});

function nextStep() {
  if (currentStep.value === 1 && selectedLanguage.value) {
    currentStep.value = 2;
  }
}

function selectContractOption(option) {
  contractOption.value = option;
  currentStep.value = 3;
}

async function submitForm() {
  if (!isFormValid.value) return;
  
  isSubmitting.value = true;
  
  try {
    const fullFormData = {
      ...formData.value,
      contractOption: contractOption.value,
      language: selectedLanguage.value.code
    };
    
    await axios.post('/api/lead-appointment', fullFormData);

    currentStep.value = 4;
  } catch (error) {
    console.error('Error submitting form:', error);
    alert(i18n.t('formError'));
  } finally {
    isSubmitting.value = false;
  }
}

function resetForm() {
  formData.value = {
    name: '',
    email: '',
    phone: '',
    message: '',
    privacyAccepted: false
  };
  currentStep.value = 1;
  selectedLanguage.value = null;
  contractOption.value = null;
  isFormValid.value = false;
}

onMounted(() => {
  selectedLanguage.value = languages[0]; // Preselect German
  i18n.locale.value = 'de'; // Set German as initial language
  localStorage.setItem('selectedLanguage', 'de');
});
</script>

<style scoped>
.contract-option {
  cursor: pointer;
  transition: transform 0.2s;
}

.contract-option:hover {
  transform: translateY(-5px);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .contract-option {
    height: 150px;
  }
}

.back-button {
  position: absolute;
  left: 16px;
  top: 16px;
}

.privacy-link {
  color: var(--v-primary-base);
  text-decoration: none;
}

.privacy-link:hover {
  text-decoration: underline;
}
</style> 