<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t("settingsGeneral.settings")
        }}</v-breadcrumbs-item>
      <v-breadcrumbs-item>{{ $t("settingsGeneral.title") }}</v-breadcrumbs-item>
    </v-breadcrumbs>
    <div style="align-items:left;margin-left:30px">
      <h3 style="text-align: left;">{{ $t("settingsGeneral.title") }}</h3>
      <img v-if="avatarUrl" class="image" :src="avatarUrl" :key="avatarUrl" alt="Avatar" style="max-width:25%" />
      <p v-for="(org, index) in organisations" :key="index">
        Organisation: {{ org ? org.name : 'Loading...' }} (ID: {{ org ? org.id : 'Loading...' }})
        <v-btn v-if="org" @click="setAsTenantId(org.id)">{{ $t("settingsGeneral.setAsTenantId") }}</v-btn>
      </p>
      <v-list>
        <v-list-item :subtitle="currentUser ? currentUser.email : 'Please log in'"
          :title="advisorData ? `${advisorData.first_name} ${advisorData.last_name || ''}`.trim() : 'Guest'"
          class="text-left"></v-list-item>
      </v-list>
      <v-select :items="languages" item-value="code" item-title="name" :label="$t('settingsGeneral.language')"
        density="compact" v-model="selectedLanguage" style="width: 200px;"
        @update:model-value="changeLanguage"></v-select>

      <!-- Notetaker Settings -->
      <div style="margin-top: 30px;">
        <h3 style="text-align: left;">{{ $t("settingsGeneral.notetakerSettings") }}</h3>
        <v-radio-group v-model="notetakerSetting" @change="saveNotetakerSetting">
          <v-radio :label="$t('settingsGeneral.allCalls')" value="all_calls"></v-radio>
          <v-radio :label="$t('settingsGeneral.onlyOrganizer')" value="self_is_organizer"></v-radio>
        </v-radio-group>
      </div>
    </div>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute } from "vue-router";
import { useUser } from "../useUser";
import i18n from "../i18n";
import axios from 'axios';

export default {
  computed: {
    currentUser() {
      return userState.currentUser;
    },
  },
  setup() {
    const languages = ref([
      { code: 'de', name: 'Deutsch' },
      { code: 'en', name: 'English' },
      { code: 'dk', name: 'Dansk' },
      { code: 'pt', name: 'Português' },
      { code: 'no', name: 'Norsk' }
    ]);
    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'de');
    const notetakerSetting = ref('self_is_organizer');

    const organisations = ref([]);
    const advisorData = ref(null);
    const avatarUrl = ref(null);
    const route = useRoute();
    const id = ref(route.params.id);
    const organisationIds = ref([]);
    const { user } = useUser();
    const isAdmin = computed(() => user.value && (user.value.id === '082d87a4-4988-4e9a-8a8b-67908e4bd1dc'));

    const changeLanguage = (languageCode) => {
      localStorage.setItem('selectedLanguage', languageCode);

      i18n.global.locale = languageCode;
      try {
        axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/api/send_language`,
          { value: languageCode },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        console.error('Error sending language:', error);
      }
    }
    const saveNotetakerSetting = async () => {
    try {
      const { data: existingSetting } = await supabase
        .from('settings')
        .select()
        .eq('user_id', userState.currentUser.id)
        .eq('feature_name', 'notetaker_join_setting')
        .single();

      if (existingSetting) {
        const { error } = await supabase
          .from('settings')
          .update({ feature_value: notetakerSetting.value })
          .eq('user_id', userState.currentUser.id)
          .eq('feature_name', 'notetaker_join_setting');

        if (error) throw error;
      } else {
        const { error } = await supabase
          .from('settings')
          .insert({
            user_id: userState.currentUser.id,
            feature_name: 'notetaker_join_setting',
            feature_value: notetakerSetting.value
          });

        if (error) throw error;
      }
    } catch (error) {
      console.error('Error saving notetaker setting:', error);
    }
};

    onMounted(async () => {
      id.value = route.params.id;
      const user = userState.currentUser;
      if (!user) throw new Error('User not found');

      // Load notetaker setting
      const { data: settings } = await supabase
        .from('settings')
        .select('feature_value')
        .eq('user_id', user.id)
        .eq('feature_name', 'notetaker_join_setting')
        .single();

      if (settings?.feature_value) {
        notetakerSetting.value = settings.feature_value;
      }

      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        throw orgError;
      }
      organisationIds.value = organisations.map(org => org.id);

      const { data: advisors, error: advError } = await supabase
        .from('advisors')
        .select('first_name, last_name, avatar_url')
        .eq('user_id', userState.currentUser.id.toString())
        .in('organisation', organisationIds.value) // new for multi-tenancy

      if (advError || orgError) throw advError || orgError;

      advisorData.value = advisors.length > 0 ? advisors[0] : null;

      const avatarPath = advisors[0].avatar_url;
      if (avatarPath) {
        const { data: imageBlob, error: imageError } = await supabase.storage.from('coveredbucket').download(avatarPath);
        if (imageBlob) {
          avatarUrl.value = URL.createObjectURL(imageBlob);
        } else {
          console.error('Failed to download avatar image:', imageError);
        }
      } else {
        avatarUrl.value = null;
      }
    });

    return {
      avatarUrl,
      organisations,
      advisorData,
      organisationIds,
      isAdmin,
      languages,
      selectedLanguage,
      changeLanguage,
      notetakerSetting,
      saveNotetakerSetting
    };
  },
}
</script>

<style scoped>
.image {
  display: block;
}
</style>