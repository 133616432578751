<!-- 
<template>
    <v-expansion-panels style="margin-left: 30px; margin-bottom: 15px; width: 95%">
      <v-expansion-panel v-if="moments && moments.length > 0">
        <v-expansion-panel-title class="h3-style" @click.stop>
          {{ $t('callsDetails.highlights') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="highlights-container">
            <div class="highlights-row">
              <div v-if="highlightVideo" class="video-container">
                <video
                  controls
                  :src="highlightVideo"
                  muted
                  autoplay
                  class="highlight-video"
                  preload="none"
                  @loadstart="isVideoLoading = true"
                  @loadeddata="isVideoLoading = false"
                >
                  Your browser does not support video playback.
                </video>
                <v-progress-circular
                  v-if="isVideoLoading"
                  indeterminate
                  color="primary"
                  class="video-loader"
                />
              </div>
  
              <ul class="highlights-list">
                <li v-for="moment in moments" :key="moment.id" class="highlight-item">
                  <span class="highlight-emoji">{{ getEmoji(moment.key) }}</span>
                  <span 
                    v-if="moment.time" 
                    @click="handleTimeClick(moment.time)" 
                    class="highlight-time"
                  >
                    [{{ formatTimestamp(moment.time) }}]
                  </span>
                  {{ moment.key }}
                </li>
              </ul>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </template> -->

  <template>
    <v-expansion-panels style="margin-left: 30px; margin-bottom: 15px; width: 95%">
      <v-expansion-panel v-if="moments && moments.length > 0">
        <v-expansion-panel-title class="h3-style" @click.stop>
          {{ $t('callsDetails.highlights') }}
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="highlights-container">
            <div class="highlights-row">
              <!-- Video on the left -->
              <div v-if="highlightVideo" class="video-container">
                <video
                  ref="videoPlayer"
                  controls
                  :src="highlightVideo"
                  muted
                  autoplay
                  class="highlight-video"
                  preload="none"
                  @loadstart="isVideoLoading = true"
                  @loadeddata="isVideoLoading = false"
                  @timeupdate="handleTimeUpdate"
                  @play="handleVideoPlay"
                >
                  Your browser does not support video playback.
                </video>
                <v-progress-circular
                  v-if="isVideoLoading"
                  indeterminate
                  color="primary"
                  class="video-loader"
                />
              </div>
  
              <!-- Key moments cards on the right -->
              <div class="highlights-grid">
                <div 
                  v-for="(moment, index) in moments" 
                  :key="moment.id" 
                  class="highlight-card"
                  :class="{ 
                    'completed': currentClipIndex > index,
                    'active': currentClipIndex === index 
                  }"
                  @click="skipToClip(index)"
                >
                  <div class="highlight-content">
                    <span class="highlight-emoji">{{ getEmoji(moment.key) }}</span>
                    <span class="highlight-time" @click="handleTimeClick(moment.time)">
                      [{{ formatTimestamp(moment.time) }}]
                    </span>
                    <span class="highlight-text">{{ moment.key }}</span>
                  </div>
                  <div v-if="currentClipIndex === index" class="progress-bar" :style="{ width: `${progressPercentage}%` }"></div>
                </div>
              </div>
            </div>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </template>

<script setup>
/* eslint-disable no-undef */
import { ref, onMounted, watch } from 'vue'
import { supabase } from '@/supabase'

const videoPlayer = ref(null)
const currentClipIndex = ref(0)
const progressPercentage = ref(0)


const handleVideoPlay = () => {
  currentClipIndex.value = 0
  progressPercentage.value = 0
}

const handleTimeUpdate = () => {
  if (!videoPlayer.value) return
  
  const clipDuration = 30
  const currentTime = videoPlayer.value.currentTime
  
  // Calculate which 30-second clip we're in
  currentClipIndex.value = Math.floor(currentTime / clipDuration)
  
  // Calculate progress within current clip
  const timeInCurrentClip = currentTime % clipDuration
  progressPercentage.value = (timeInCurrentClip / clipDuration) * 100
}

const formatTimestamp = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`
}
const skipToClip = (index) => {
  if (!videoPlayer.value) return
  videoPlayer.value.currentTime = index * 30 // Skip to start of clicked clip
  videoPlayer.value.play()
}

const getEmoji = (key) => {
  const emojiMap = {
    'Kunde erwähnt einen anderen Berater/Wettbewerber': '🏢',
    'Kunde und Berater stimmen zu oder nicht zu Ansatz oder nächsten Schritten': '🤝',
    'Berater bietet etwas an (z.B. Zweitmeinung), aber Kunde lehnt ab': '🚫',
    'Entscheidung über Follow-up oder Kauf wird getroffen / nicht getroffen': '💼',
    'Kunde weigert sich, persönliche oder Kontaktinformationen zu teilen': '🔒',
    'Berater stellt eine Frage, beantwortet sie aber selbst, bevor der Kunde wirklich antworten kann': '🗣️',
    'Bedarf vernachlässigt': '🧐',
    'Advisor monolog (>80s)': '🕰️'
  }
  return emojiMap[key] || '📌' // Default emoji if no match found
}

// Add /* eslint-disable no-undef */ at the top of the script
const props = defineProps({
  callId: {
    type: [String, Number],
    required: true
  },
  moments: {
    type: Array,
    required: true
  }
})

const emits = defineEmits(['play-audio'])

const handleTimeClick = (time) => {
  emits('play-audio', time)
}

const highlightVideo = ref(null)
const isVideoLoading = ref(false)

const fetchHighlightVideo = async () => {
  try {
    const { data: call } = await supabase
      .from('calls')
      .select('highlight_video')
      .eq('id', props.callId)
      .single()
      
    if (call?.highlight_video) {
      const { data: videoBlob, error: videoError } = await supabase
        .storage
        .from('coveredbucket')
        .download(call.highlight_video)

      if (videoError) {
        console.error('Error downloading video:', videoError)
        return
      }

      highlightVideo.value = URL.createObjectURL(videoBlob)
    }
  } catch (error) {
    console.error('Error fetching highlight video:', error)
  }
}

onMounted(fetchHighlightVideo)
watch(() => props.callId, fetchHighlightVideo)
</script>

<style scoped>
.highlights-grid {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  align-content: start;
}

.highlight-card {
  position: relative;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 12px;
  height: 60px;
  overflow: hidden;
}


.highlight-card:hover {
  border-color: #5932EA;
  background: rgba(89, 50, 234, 0.05); /* Light purple background on hover */
}

.highlight-card.completed {
  background: rgba(0, 0, 0, 0.1);
}

.highlight-card.active {
  border-color: #5932EA;
}

.highlight-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.highlight-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: #5932EA;
  transition: width 0.1s linear;
}

.highlight-time {
  flex-shrink: 0;
  cursor: pointer;
  color: #5932EA;
}

.highlight-time:hover {
  text-decoration: underline;
}

.highlights-container {
  text-align: left;
  margin-left: 30px;
}

.highlights-row {
  display: flex;
  gap: 40px;
  align-items: flex-start;
}

.video-container {
  flex: 1;
  position: relative;
  aspect-ratio: 16/9;
  background: #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
}

.highlights-list {
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.highlight-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.highlights-list {
  list-style-type: none;
  padding: 0;
}

.highlight-item {
  color: gray;
  margin-bottom: 8px;
  padding-left: 30px;
}

.highlight-emoji {
  margin-right: 8px;
}

</style> 