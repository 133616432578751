<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-title class="h3-style">{{ $t('summary.title') }}</v-expansion-panel-title>
            <v-expansion-panel-text>
                <div class="d-flex">
                    <div class="flex-grow-1 text-left">
                        <div v-if="parsedSummary" class="summary-content">
                            <div class="section">
                                <h3>{{ $t('summary.customerSituation') }}</h3>
                                <p>{{ parsedSummary.customer_situation }}</p>
                            </div>

                            <div class="section">
                                <h3>{{ $t('summary.productsDiscussed') }}</h3>
                                <ul>
                                    <li v-for="(product, index) in parsedSummary.products_discussed" :key="index">
                                        {{ product }}
                                    </li>
                                </ul>
                            </div>

                            <div class="section">
                                <h3>{{ $t('summary.openQuestions') }}</h3>
                                <ul>
                                    <li v-for="(question, index) in parsedSummary.open_questions" :key="index">
                                        {{ question }}
                                    </li>
                                </ul>
                            </div>

                            <div class="section">
                                <h3>{{ advisorName }} To-Dos</h3>
                                <ul>
                                    <li v-for="(todo, index) in parsedSummary.advisor_todos" :key="index">
                                        {{ todo }}
                                    </li>
                                </ul>
                            </div>

                            <div class="section">
                                <h3>{{ customerName }} To-Dos</h3>
                                <ul>
                                    <li v-for="(todo, index) in parsedSummary.customer_todos" :key="index">
                                        {{ todo }}
                                    </li>
                                </ul>
                            </div>

                            <div class="section">
                                <h3>{{ $t('summary.nextSteps') }}</h3>
                                <ul>
                                    <li v-for="(step, index) in parsedSummary.next_steps" :key="index">
                                        {{ step }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-else>
                            Error parsing summary
                        </div>
                    </div>
                    <v-btn style="align-self: flex-start;" icon small @click="copyToClipboard"
                        :color="copySuccess ? 'success' : 'primary'">
                        <v-icon>{{ copySuccess ? '$check' : '$contentCopy' }}</v-icon>
                    </v-btn>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        summary: String,
        advisorName: {
            type: String,
            default: 'Advisor'
        },
        customerName: {
            type: String,
            default: 'Customer'
        }
    },
    data() {
        return {
            copySuccess: false,
            parsedSummary: null
        }
    },
    watch: {
        summary: {
            immediate: true,
            handler(newVal) {
                try {
                    if (typeof newVal === 'string') {
                        const cleanJson = newVal.replace(/^"(.*)"$/, '$1');
                        const parsedData = JSON.parse(cleanJson);
                        this.parsedSummary = parsedData.summary;
                    } else if (newVal) {
                        this.parsedSummary = newVal.summary;
                    }
                } catch (error) {
                    console.error('Error parsing summary:', error);
                    this.parsedSummary = null;
                }
            }
        }
    },
    methods: {
        copyToClipboard() {
            if (!this.parsedSummary) return;

            const formattedText = `${this.$t('summary.title')}

${this.$t('summary.customerSituation')}:
${this.parsedSummary.customer_situation}

${this.$t('summary.productsDiscussed')}:
${this.parsedSummary.products_discussed.length > 0 ? this.parsedSummary.products_discussed.map(product => `• ${product}`).join('\n') : 'None'}

${this.$t('summary.openQuestions')}:
${this.parsedSummary.open_questions.length > 0 ? this.parsedSummary.open_questions.map(question => `• ${question}`).join('\n') : 'None'}

${this.advisorName} To-Dos:
${this.parsedSummary.advisor_todos.length > 0 ? this.parsedSummary.advisor_todos.map(todo => `• ${todo}`).join('\n') : 'None'}

${this.customerName} To-Dos:
${this.parsedSummary.customer_todos.length > 0 ? this.parsedSummary.customer_todos.map(todo => `• ${todo}`).join('\n') : 'None'}

${this.$t('summary.nextSteps')}:
${this.parsedSummary.next_steps.length > 0 ? this.parsedSummary.next_steps.map(step => `• ${step}`).join('\n') : 'None'}`;

            navigator.clipboard.writeText(formattedText).then(() => {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 2000);
            });
        }
    }
}
</script>

<style scoped>
.h3-style {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.summary-content {
    padding: 0 1rem;
}

.section {
    margin-bottom: 1.5rem;
}

.section h3 {
    color: #5932EA;
    margin-bottom: 0.5rem;
    font-size: 1.1em;
}

.section ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
}

.section ul li {
    padding: 0.25rem 0;
    position: relative;
    padding-left: 1.5rem;
}

.section ul li:before {
    content: "•";
    color: #5932EA;
    position: absolute;
    left: 0.5rem;
}

.section p {
    margin: 0;
    line-height: 1.4;
}
</style>